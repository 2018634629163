<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 学习资料管理 </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-input v-model="courseName" placeholder="请输入课程名称" />
        <a-button type="primary" class="btn" :loading="loading" @click="getData()">搜索</a-button>
        <a-button v-show="type == 2" class="all_boder_btn" @click="editFilling(1)">新建</a-button>
      </template>
    </HeaderBox>

    <div class="table-box">
      <a-tabs :default-active-key="type" @change="callback">
        <a-tab-pane key="2" tab="通用资料"></a-tab-pane>
        <a-tab-pane key="1" tab="课程讲义"></a-tab-pane>
      </a-tabs>
    </div>

    <a-table class="table-template" @change="onPage" :rowKey="(item) => item.id" :loading="loading" :columns="columns"
      :data-source="tableData" :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }">
      <template slot="index" slot-scope="item, row, i">
        {{ (pageNumber - 1) * pageSize + i + 1 }}
      </template>
      <!-- 资料类型 -->
      <template slot="materialType" slot-scope="item">
        {{ item == "1" ? "课程课件" : item == "2" ? "章节课件" : item == "3" ? "通用课件" : "-" }}
      </template>
      <!-- 排序 -->
      <template slot="materialSort" slot-scope="item, row">
        <a-input-number id="inputNumber" :disabled="loading || type == 1" v-model="item" :min="1"
          @change="onSortChange(item, row)" />
      </template>

      <template slot="operation" slot-scope="item, row, index">
        <span class="blueText" v-if="type == 2">
          <span @click="verifyAction(row, 1)">编辑</span> |
          <span @click="verifyAction(row, 2)">删除</span>
        </span>
        <span v-else>-</span>
      </template>
    </a-table>

    <!-- 添加/编辑课件 -->
    <a-modal v-model="ModalShow" align="center" :maskClosable="false" :title="state == 1 ? '添加资料' : '编辑资料'">
      <div class="all_content_box">
        <a-row class="a-row-item">
          <a-col :span="6" class="a-row-left"><span class="name all_required"
              style="line-height: 32px">资料名称：</span></a-col>
          <a-col :span="15">
            <a-input v-model="formData.name" placeholder="请填写资料名称" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="6" class="a-row-left"><span class="name all_required"
              style="line-height: 32px">上传课件：</span></a-col>
          <a-col :span="15" class="col-right">
            <a-upload name="videoFileId" accept=".pdf" :beforeUpload="beforeUpload" :showUploadList="false"
              :customRequest="customRequest">
              <a-button ref="btn" v-show="state == 1">
                <template v-if="uploadLoading">
                  <a-icon type="loading" />
                  <span>上传中</span>
                </template>
                <template v-else>
                  <a-icon type="upload" />
                  <span>{{ formData.url ? '重新上传' : '选择课程文件' }}</span>
                </template>
              </a-button>
            </a-upload>
            <a-button v-show="state == 2" @click="handleBeforeUpload">
              <a-icon type="upload" />
              <span>重新上传</span>
            </a-button>
            <p v-if="formData.url">
              <span>上传成功：</span>
              <span>{{ FunFormat(formData.url) }}</span>
            </p>
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="6" class="a-row-left"><span class="name all_required"
              style="line-height: 32px">允许下载：</span></a-col>
          <a-col :span="6">
            <a-radio-group v-model="formData.downloadFlag">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="6" class="a-row-left"><span class="name"
              style="line-height: 32px">资料包名：</span></a-col>
          <a-col :span="15">
            <a-input v-model="formData.sectionName" placeholder="请填写资料包名" />
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button @click="editCancel">取消</a-button>
        <a-button type="primary" :loading="loading" @click="addEditData">确认</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "资料名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "课程名",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "章节名",
    align: "center",
    dataIndex: "sectionName",
  },
  {
    title: "课件类型",
    align: "center",
    dataIndex: "materialType",
    scopedSlots: { customRender: "materialType" },
  },
  {
    title: "排序",
    align: "center",
    dataIndex: "materialSort",
    scopedSlots: { customRender: "materialSort" },
  },
  {
    title: "创建人",
    align: "center",
    dataIndex: "creator",
  },
  {
    title: "创建时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      columns,
      loading: false,
      state: 1, //1添加 2编辑
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,
      courseName: null,// 课程名称
      name: null,// 资料名称
      type: 2,// 资料类型 1.课程讲义 2.非课程课件
      ModalShow: false,
      // 添加/编辑 课件表单
      formData: {
        id: null,
        name: '',
        materialType: 3,
        url: '',
        downloadFlag: 0,
        sectionName: null,
      },
      filterOption: '',
      selectList: [],  // 章节列表
      uploadLoading: false, // 文件上传状态
      verifyLoading: false,
    };
  }, // 事件处理器
  methods: {
    callback(e) {
      this.pageNumber = 1
      this.type = e;
      this.getData();
    },
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // 分类
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/course/selectStudyMaterialList",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          type: this.type,
          courseName: this.courseName,
          name: this.name,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 新增/修改
    addEditData() {
      if (!this.formData.name) {
        this.$message.warning("请填写资料名称");
        return;
      }
      if (!this.formData.url) {
        this.$message.warning("请上传资料");
        return;
      }
      let url = "";
      let params = {};
      let method = "";
      // 新增
      if (this.state == 1) {
        url = "/hxclass-management/course/material/insert";
        method = "post";
        params = this.formData;
      }
      // 编辑
      else if (this.state == 2) {
        url = "/hxclass-management/course/material/update";
        method = "PUT";
        params = this.formData;
      }
      this.loading = true;
      this.$ajax({
        url,
        method,
        params,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.formData.name = '';
          this.formData.url = '';
          this.formData.downloadFlag = 0;
          this.formData.id = null;
          this.formData.sectionName = null;
          this.getData();

          this.$message.success(this.state == 1 ? "新增成功" : "修改成功");
          this.ModalShow = false;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 删除
    deleteData(item) {
      // console.log(item)
      let _this = this;
      this.$confirm({
        title: "确定删除该资料吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/course/material/del/" + item.id,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.pageNumber = 1
                _this.getData();
                _this.$message.success("删除成功");
              }
            });
        },
        onCancel() { },
      });
    },
    // 修改排序
    onSortChange(e, item) {
      if (!e) {
        return;
      }
      item.materialSort = e;
      this.loading = true;
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.timer = null;
        this.$ajax({
          url: '/hxclass-management/course/material/update',
          method: 'PUT',
          params: item,
        }).then(res => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.$message.success("修改成功");
            this.getData()
          } else {
            this.$message.error(res.message)
          }
        })
      }, 900)

    },
    // 打开弹出层
    editFilling(type, item) {
      console.log(JSON.stringify(item));
      this.state = type;
      this.ModalShow = true;
      this.formData.name = item.name;
      this.formData.url = item.url;
      this.formData.downloadFlag = item.downloadFlag;
      this.formData.id = item.id;
      this.formData.sectionName = item.sectionName;
    },
    editCancel() {
      this.ModalShow = false;
      this.formData.name = '';
      this.formData.url = '';
      this.formData.downloadFlag = 0;
      this.formData.id = null;
      this.formData.sectionName = null;
    },

    // 课件编辑上传文件前提醒
    handleBeforeUpload(e) {
      let _this = this
      _this.$confirm({
        title: '选择文件后即完成资料更新，是否继续选择文件?',
        okText: '确认',
        onOk() {
          _this.$refs["btn"].$el.click()
        },
        onCancel() {
        },
      });
    },
    // 文件上传前钩子
    beforeUpload(file) {
      if (!this.$regular.fileReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.fileReg.msg);
        return false;
      }
    },

    // 文件上传
    customRequest(fileData) {
      this.uploadLoading = true
      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file, bucket, this.fileProgressCallback, (url, fileName) => {
        // 上传完成
        if (url) {
          if (this.state == 2) {
            this.onUpdateFile(url)
          } else {
            this.formData.url = url
          }
        } else {
          this.$message.error("上传失败");
        }
        this.uploadLoading = false
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
      * progress 进度
      * speed 传输速度
      * name 文件名称
      * */
    },
    // 格式化文件名称
    FunFormat(file) {
      var dateLength = file.length;
      var ednStr = file.substring(dateLength - 16, dateLength);
      var str = '.......' + ednStr
      return str
    },
    // 校验是否已存在分配给用户的资料 type 1:编辑  2：删除
    verifyAction(item, type) {
      this.verifyLoading = true;
      this.$ajax({
        url: "/hxclass-management/course/getUserMaterialUseNum",
        method: "get",
        params: {
          materialId: item.id,
        },
      }).then((res) => {
        this.verifyLoading = false;
        if (res.code == 200 && res.success) {
          if (res.data > 0) {
            if (type == 1) {
              this.$message.warning("已存在下发课件资料，更新的课件资料不会更新已分配用户");
              this.editFilling(2, item);
            } else if (type == 2) {
              this.$message.warning("已存在下发用户的课件资料，删除后原用户无法观看，请联系管理员操作");
              return;
            }
          } else {
            if (type == 1) {
              this.editFilling(2, item);
            } else if (type == 2) {
              this.deleteData(item);
            }
          }
        } else {
          this.$message.error(res.message)
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    // 资讯分类
    this.getData();
  }, // 生命周期-实例挂载后调用
  mounted() { }, // 生命周期-实例销毁离开后调用
  destroyed() { }, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less"  scoped>
.all_main_wrap {
  min-height: 100%;
}

.all_content_box {
  .all_input {
    width: 340px;
  }
}

/deep/.ant-modal-header {
  padding: 16px 24px 0px;
  border-bottom: none;
}

/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}

/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}

/deep/ .ant-modal-body {
  padding: 24px 24px 14px;
}

/deep/ .ant-btn {
  margin-right: 24px;
}

.blueText span {
  cursor: pointer;
}

.all_content_box {
  .a-row-item {
    margin-bottom: 20px;

    .a-row-left {
      margin-right: 10px;
    }

    .name {
      width: 100%;
      display: inline-block;
      text-align: right;
    }

    .right {
      width: 100%;
    }
  }

  .tips {
    font-size: 17px;
    color: #333;
    margin: 0 0 20px;
  }
}

/deep/ .ant-modal-footer {
  text-align: center;
  padding-bottom: 30px;
}

.radio {
  margin-top: 5px;
}

.col-right {
  text-align: left;
}

.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
</style>